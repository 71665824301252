<template>
  <div class="doc-container">
    <!-- <div class="head">
      <div class="head-container w1200">
        <div class="logo" >
          <div class="mobile-menu-btn" @click="handleMenuBtn">
            <a-icon type="menu" />
          </div>
          <logo :height="40" @click="toHomePage"></logo>
        </div>
        <div></div>
      </div>
    </div> -->

    <pageHeader class="page-header"></pageHeader>

    <div class="content-body w1200">

      <div class="side-menu" :class="{'m-show': sideMenuMobileShow}">
        <div class="side-menu-mask"></div>
        <div class="side-menu-container">
          <ul>
            <li
              v-for="(item, index) in menulist"
              :key="index"
              @click="toPath(item.fileName)"
              class="menu-item">
              {{ item.name }}
            </li>
          </ul>
        </div>
      </div>

      <div class="page-content">
        <router-view></router-view>
      </div>
    </div>

    <!-- <div class="foot"></div> -->
    <pageFooter></pageFooter>
  </div>
</template>

<script>
import { camelToDashLine } from '@/utils/func.js'
// import logo from '@/components/logo.vue'
import pageHeader from '@/components/layout/pageHeader.vue'
import pageFooter from '@/components/layout/pageFooter.vue'

export default {
  name: 'doc',
  components: {
    // logo,
    pageHeader,
    pageFooter
  },
  data () {
    return {
      menulist: [
        { name: 'Terms of Service', fileName: 'TermsOfService' },
        { name: 'Privacy Policy', fileName: 'PrivacyPolicy' }
        // { name: 'Introduction', fileName: 'Introduction' },
        // { name: 'Quick Start', fileName: 'QuickStart' }
      ],
      sideMenuMobileShow: false
    }
  },

  created () {
    if (this.$route.name === 'doc') {
      const indexPage = this.menulist[0]
      this.toPath(indexPage.fileName, true)
    }
  },

  methods: {

    // fileName对应 src/views/Doc/Pages/文件名（无后缀）
    toPath (fileName, replace) {
      const path = '/docs/' + camelToDashLine(fileName)

      if (this.$route.path === path) {
        return
      }

      if (replace) {
        this.$router.replace(path)
        return
      }

      this.$router.push(path)
    },
    toHomePage () {
      this.$router.replace('/')
    },

    handleMenuBtn () {
      this.sideMenuMobileShow = !this.sideMenuMobileShow
    }
  }
}
</script>

<style lang="scss" scoped>

.doc-container{
  max-height: 100vh;
  overflow-y: auto;
}
.head{
  height: 60px;
  border-bottom: 1px solid #ddd;
  box-shadow: 0 0 10px #ddd;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;
  background: #fff;
}

.page-header {
  background: linear-gradient(#E9EAFF, #FBFBFF);
}

// .head-container{
//   height: 100%;
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   .logo{
//     display: flex;
//     align-items: center;

//     img{
//       height: 40px;
//     }
//   }

//   .mobile-menu-btn{
//     display: none;
//   }
// }

.content-body{
  display: flex;
  padding-top: 100px;
  min-height: 100vh;
}

.side-menu{
  min-width: 220px;
  border-right: 1px solid #ddd;
  padding: 8px 0;

  ul{
    padding-left: 0;
    list-style: none;
    text-align: left;
  }
}

.menu-item{
  cursor: pointer;
  margin: 8px 0;
}

.page-content{
  flex: 1;
  min-width: 0;
  padding: 24px;
}

@media screen  and (max-width: $mobileWidth){

  .head{
    .mobile-menu-btn{
      display: block;
      padding: 0 12px;
    }

    .logo{
      ::v-deep img{
        height: 28px !important;
      }
    }
  }
  .side-menu{
    position: fixed;
    left: -220px;
    top: 0;
    bottom: 0;
    transition: left 0.2s;

    .side-menu-container{
      padding: 8px 12px;
    }

    &.m-show{
      // position: relative;
      padding-top: 60px;
      left: 0;
      background: #fff;
      box-shadow: 0 0 10px #ddd;
    }
  }
}

</style>
